import { Ref, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export const useAvatarBrowse = (
  photoUrl: Ref<string>,
  updateDispatchName: string,
  deleteDispatchName: string,
) => {
  const store = useStore()
  const route = useRoute()
  const isUploading = ref(false)
  const photoFile = ref<File | null>(null)
  const isReset = ref(true)
  const isDeleting = ref(false)
  const returnPhotoUrl = ref<String | null>(photoUrl.value)

  const handleAvatarUpdate = async (uid?: String, payload?: Object) => {
    isUploading.value = true
    let data = {
      photoFile: photoFile.value,
      id: uid ?? route.params.id,
      action: 'create',
    }
    if (payload) {
      data = { ...data, ...payload }
    }
    const uploadUrl = await store.dispatch(updateDispatchName, data)
    handleAvatarReset()
    isUploading.value = false
    returnPhotoUrl.value = uploadUrl
  }

  const handleAvatarReset = () => {
    photoFile.value = null
    isReset.value = true
  }

  const handlePhotoSelected = (file: File) => {
    photoFile.value = file
    isReset.value = file ? false : true
  }

  const handleAvatarDelete = async (id?: String) => {
    isDeleting.value = true
    await store.dispatch(deleteDispatchName, {
      id: id ?? route.params.id,
      action: 'delete',
    })
    handleAvatarReset()
    isDeleting.value = false
    returnPhotoUrl.value = null
  }

  return {
    isReset,
    isUploading,
    isDeleting,
    returnPhotoUrl,
    handlePhotoSelected,
    handleAvatarUpdate,
    handleAvatarDelete,
    handleAvatarReset,
  }
}
