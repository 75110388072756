<template>
  <div>
    <div class="card-header">
      <h4 class="mb-0">
        <strong>Basic</strong>
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-7">
          <a-form
            :model="editForm"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            layout="vertical"
          >
            <a-form-item name="email" label="Email">
              <a-input v-model:value="editForm.email" placeholder="Email" disabled />
            </a-form-item>
            <a-form-item name="name" label="Name">
              <a-input v-model:value="editForm.name" placeholder="Your Name" />
            </a-form-item>
            <a-button
              :disabled="!hasChanged"
              :loading="isUploading"
              type="primary"
              html-type="submit"
              class="text-center mr-3"
              @click="handleUpdate"
            >
              Update
            </a-button>
            <a-button :disabled="!hasChanged" class="text-center" @click="handleReset">
              Reset
            </a-button>
          </a-form>
        </div>
        <div class="col-lg-5">
          <avatar-browse
            :avatar-size="128"
            :photo-url="photoUrl ?? null"
            :reset="isReset"
            :is-uploading="isUploading"
            :is-deleting="isDeleting"
            :show-delete="true"
            @photoSelected="handlePhotoSelected"
            @deleteExisting="handleAvatarDelete"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AppUser } from '@/services/firebase/modules/users/users.types'
import { computed, reactive, watchEffect, defineComponent } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import AvatarBrowse from '@/components/General/AvatarBrowse.vue'
import { useAvatarBrowse } from '@/hooks/useAvatarBrowseHook'

export default defineComponent({
  name: 'UserPorfileBasic',
  components: {
    AvatarBrowse,
  },
  setup() {
    const store = useStore()
    const user = computed<AppUser>(() => store.getters['user/user'])
    const photoUrl = computed(() => user.value.photoUrl)
    const {
      isReset,
      isUploading,
      isDeleting,
      handlePhotoSelected,
      handleAvatarUpdate,
      handleAvatarDelete,
      handleAvatarReset,
    } = useAvatarBrowse(
      photoUrl,
      `user/${actions.UsersAction.UPDATE_BASIC_USER_PROFILE}`,
      `user/${actions.UsersAction.UPDATE_BASIC_USER_PROFILE}`,
    )

    // TODO: Handle whitespace in user name update
    const editForm = reactive({
      name: user.value.name,
      email: user.value.email,
    })

    const hasChanged = computed(() => {
      if (user.value.name != editForm.name.trim() || !isReset.value) {
        return true
      }
      return false
    })

    watchEffect(() => {
      // watchEffect - We don’t watch one specific reactive value but every reactive value inside its callback function.
      editForm.name = user.value.name
      editForm.email = user.value.email
    })

    const rules = {
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
      ],
      name: [{ required: true, message: 'Please input Name!', trigger: 'change' }],
    }

    const handleUpdate = async () => {
      const data = {
        name: user.value.name != editForm.name.trim() ? editForm.name : null,
      }
      handleAvatarUpdate(user.value.id, data)
    }

    const handleReset = () => {
      editForm.name = user.value.name
      handleAvatarReset()
    }

    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      editForm,
      rules,
      hasChanged,
      photoUrl,
      isReset,
      isUploading,
      isDeleting,
      handleReset,
      handlePhotoSelected,
      handleUpdate,
      handleAvatarDelete,
    }
  },
})
</script>
