<template>
  <div>
    <div class="d-flex flex-wrap flex-column align-items-start">
      <a-avatar
        :size="size"
        :src="url"
        style="color: #595c97; background-color: #f2f4f8;"
        class="mb-4"
      >
        <template #icon><UserOutlined :style="{ fontSize: '32px' }"/></template>
      </a-avatar>
      <div class="d-flex flex-column justify-content-around">
        <div class="mb-2">
          <span class="mr-2">Preview:</span>
          <a-switch
            v-model:checked="previewChecked"
            size="small"
            @change="handlePreviewChange"
            :disabled="fileList.length === 0"
          />
        </div>
        <div v-if="isShowDelete">
          <a-popconfirm
            title="Are you sure you want to delete this?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDelete"
          >
            <a-tooltip placement="right" title="Delete Existing">
              <a-button class="mb-2" :loading="isDeleteLoading" :disabled="isDeleteDisabled">
                <template #icon><DeleteOutlined /></template>Delete
              </a-button>
            </a-tooltip>
          </a-popconfirm>
        </div>
        <a-upload
          accept="image/*"
          :before-upload="beforeUpload"
          :remove="handleRemove"
          :multiple="false"
          list-type="picture"
          v-model:file-list="fileList"
        >
          <a-button class="mb-2">
            <upload-outlined></upload-outlined>
            Browse
          </a-button>
        </a-upload>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { UploadOutlined, UserOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { computed, defineComponent, ref, watch, watchEffect } from 'vue'

export default defineComponent({
  name: 'AvatarBrowse',
  components: {
    UploadOutlined,
    UserOutlined,
    DeleteOutlined,
  },
  props: {
    photoUrl: {
      type: String,
      default: () => null,
    },
    avatarSize: {
      type: Number,
      default: () => 64,
    },
    reset: {
      type: Boolean,
      default: () => false,
    },
    showDelete: {
      type: Boolean,
      default: () => true,
    },
    isDeleting: {
      type: Boolean,
      default: () => false,
    },
    isUploading: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['photoSelected', 'deleteExisting'],
  setup(props, { emit }) {
    const fileList = ref([])
    const photoUrlProp = computed(() => props.photoUrl)
    const isDeleteDisabled = computed(() => !props.photoUrl || props.isUploading)
    const isDeleteLoading = computed(() => props.isDeleting)
    // const isUploadingPhoto = computed(() => props.isUploading)
    const size = computed(() => props.avatarSize)
    const resetUpload = computed(() => props.reset)
    const url = ref<string | null>(props.photoUrl)
    const isShowDelete = ref(props.showDelete)
    const objectUrl = ref<string | null>(null)
    const previewChecked = ref<boolean>(false)

    const beforeUpload = (file: File) => {
      const isSizeAllowed = file.size / 1024 / 1024 < 11 // Is Size <= 10MB
      objectUrl.value = getObjectUrl(file)
      if (isSizeAllowed) {
        emit('photoSelected', file)
      } else {
        // Emit null file
        handleRemove()
        message.warn('Size should be less then equal to 10MB.')
      }

      return false
    }

    const handleRemove = () => {
      fileList.value = []
      url.value = props.photoUrl
      previewChecked.value = false
      emit('photoSelected', null)
    }

    const handleDelete = () => {
      emit('deleteExisting')
    }

    const getObjectUrl = (file: any) => {
      return file ? URL.createObjectURL(file) : null
    }

    const handlePreviewChange = (checked: boolean) => {
      url.value = checked && fileList.value.length != 0 ? objectUrl.value : photoUrlProp.value
    }

    // If received reset=true from parent, empty the fileList
    watchEffect(() => {
      // Dont emit null file. Let parent handle it.
      if (resetUpload.value) {
        fileList.value = []
        previewChecked.value = false
        handlePreviewChange(false)
      }
    })

    // If more then 1 file, take the latest
    watch(fileList, () => {
      if (fileList.value.length > 1) {
        fileList.value = [fileList.value[fileList.value.length - 1]]
        handlePreviewChange(previewChecked.value)
      }
    })

    return {
      fileList,
      url,
      beforeUpload,
      handleRemove,
      size,
      isShowDelete,
      isDeleteLoading,
      previewChecked,
      handlePreviewChange,
      isDeleteDisabled,
      handleDelete,
    }
  },
})
</script>
<style scoped></style>
