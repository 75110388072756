<template>
  <div>
    <div class="card-header">
      <h4 class="mb-0">
        <strong>Security</strong>
      </h4>
    </div>
    <div class="card-body">
      <a-form
        :model="editForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="vertical"
      >
        <a-form-item name="email" label="Email">
          <a-input v-model:value="editForm.email" placeholder="Email" disabled />
        </a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          class="text-center"
          @click.prevent="handlePasswordReset"
          :loading="loading"
        >
          Send Password Reset Link
        </a-button>
      </a-form>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'

export default {
  name: 'UserProfileSecurity',
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])
    const loading = ref(false)

    const editForm = reactive({
      name: user.value.name,
      email: user.value.email,
    })

    const rules = {
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
      ],
    }

    const handlePasswordReset = async () => {
      loading.value = true
      await store.dispatch(`user/${actions.UsersAction.RESET_USER_PASSWORD}`, {
        email: user.value.email,
      })
      loading.value = false
    }

    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      editForm,
      rules,
      loading,
      handlePasswordReset,
    }
  },
}
</script>
