<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-top card-top-success">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3" style="border-right: 1px solid #f0f0f0; padding: 0px">
                <a-menu
                  mode="vertical"
                  v-model:selected-keys="selectedKey"
                  style="border: 0px; width: auto;"
                >
                  <a-menu-item key="basic">Basic</a-menu-item>
                  <a-menu-item key="security">Security</a-menu-item>
                  <a-menu-item key="custom" disabled>Custom</a-menu-item>
                  <a-menu-item key="notifications" disabled>Notifications</a-menu-item>
                </a-menu>
              </div>
              <div class="col-lg-9">
                <div v-if="selectedKey[0] === 'basic'">
                  <UserPorfileBasic />
                </div>
                <div v-if="selectedKey[0] === 'security'">
                  <p><UserProfileSecurity /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import UserPorfileBasic from './UserProfileBasic'
import UserProfileSecurity from './UserProfileSecurity'

export default {
  name: 'UserProfile',
  components: {
    UserPorfileBasic,
    UserProfileSecurity,
  },
  setup() {
    const selectedKey = ref(['basic'])
    return {
      selectedKey,
    }
  },
}
</script>
